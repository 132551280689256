import React, { Component } from 'react';
import * as emailjs from 'emailjs-com';
import { sendAuto, sendToMe } from '../../Utils/email';
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';
import { Phone } from '..'
import Joi from 'joi-browser';
import style from './style.css';

const schema = Joi.object().keys({
    string: Joi.string().min(1),
    phone: Joi.string().regex(/^[()0-9+-]*$/),
    email: Joi.string().email({ minDomainAtoms: 2 }),
});

export default class Contact extends Component {

    state = {
        name: "",
        email: "",
        phone: "",
        message: "",
        disabled: true,
        validName: this.props.name ? true : false,
        validEmail: this.props.email ? true : false,
        validPhone: this.props.phone ? true : false,
        validMessage: this.props.message ? true : false,
        errorLabelName: false,
        errorLabelPhone: false,
        errorLabelEmail: false,
        errorLabelMessage: false
    }

    onSend = (e) => {
        e.preventDefault()

        this.setState({
            disabled: true,
            name: "",
            email: "",
            phone: "",
            message: "",
        })

        sendAuto(this.state.email, this.state.name).then((response) => {
            console.log("EMAIL SENT SUCCESSFULLY", response);
        })
        sendToMe(this.state.message, this.state.email, this.state.name, this.state.phone).then((response) => {
            this.props.toggle();
            console.log("EMAIL SENT TO ME SUCCESSFULLY", response);
        })

    }

    onChange = ({ target }) => {
        let { name, value } = target;
        let { validEmail, validPhone, validMessage, validName } = this.state;
        // let phoneNumber = parsePhoneNumberFromString(`${this.state.phone}`, 'US');

        switch (name) {
            case "name":
                var err = Joi.validate({ string: value }, schema);
                if (err.error) {
                    this.setState({
                        validName: false,
                        errorLabelName: true,
                        disabled: true,
                        [name]: value
                    })
                } else {
                    console.log("valid name: ", this.state);
                    let disabled = true;
                    if (validEmail && validPhone && validMessage) {
                        disabled = false;
                    }
                    this.setState({
                        [name]: value,
                        validName: true,
                        errorLabelName: false,
                        disabled: disabled,
                        [name]: value
                    })
                }
                break;
            case "email":
                var err = Joi.validate({ email: value }, schema);

                if (err.error) {
                    this.setState({
                        validEmail: false,
                        errorLabelEmail: true,
                        disabled: true,
                        [name]: value
                    })
                } else {
                    console.log("Valid email: ", this.state);
                    let disabled = true;
                    if (validName && validPhone > 0 && validMessage) {
                        disabled = false;
                    }
                    this.setState({
                        validEmail: true,
                        errorLabelEmail: false,
                        disabled: disabled,
                        [name]: value
                    })
                }
                break;
            case "phone":
                let text = new AsYouType('US').input(value);
                if (value.length > this.state.phone.length) {
                    value = text
                }
                // // value = value.length > 3 ? text : value;
                // if (value.length === 3 && value.indexOf('(') === -1) {
                //     value = text;
                // } else if (value.length === 4 && value.indexOf('(') > -1) {
                //     value = value;
                // } else {
                //     value = text;
                // }

                if (value.length !== 14 && value.length !== 0) {
                    this.setState({
                        errorLabelPhone: true,
                        validPhone: false,
                        disabled: true,
                        [name]: value
                    }
                    )
                } else {
                    console.log("STATE ON PHONE CHANGE ", this.state)
                    let disabled = true;
                    if (validEmail && validMessage && validName) {
                        disabled = false;
                    }
                    console.log("valid Phone: ", this.state);
                    this.setState({
                        validPhone: true,
                        errorLabelPhone: false,
                        disabled: disabled,
                        [name]: value
                    })
                }
                break;
            case "message":
                var err = Joi.validate({ string: value }, schema);
                if (err.error) {
                    this.setState({
                        validMessage: false,
                        errorLabelMessage: true,
                        disabled: true,
                        [name]: value
                    })
                } else {
                    console.log("STATE ON MESSAGE CHANGE ", this.state)
                    console.log("valid Message: ", this.state);
                    let disabled = true;
                    if (validEmail && validPhone && validName) {
                        disabled = false;
                    }
                    this.setState({
                        validMessage: true,
                        errorLabelMessage: false,
                        disabled: disabled,
                        [name]: value
                    })
                }
                break;
            default:
                this.setState({
                    [name]: value
                })
                break;
        }
    }

    warningName() {
        return this.state.errorLabelName ? <span className={style.errorLabel}>Please enter a valid Name.</span> : null
    }

    warningPhone() {
        return this.state.errorLabelPhone ? <span className={style.errorLabel}>Please enter a valid phone number.</span> : null
    }

    warningEmail() {
        return this.state.errorLabelEmail ? <span className={style.errorLabel}>Please enter a valid Email.</span> : null
    }

    warningMessage() {
        return this.state.errorLabelMessage ? <span className={style.errorLabel}>Please enter a valid Message.</span> : null
    }

    render() {
        return (
            <div className={style.contactWrapper}>
                <i onClick={this.props.toggle} className={`material-icons ${style.x}`}>clear</i>
                <div className={style.header}>
                    <h1>Contact Us</h1>
                </div>
                <div className={style.contentWrapper}>
                    <div className={style.flexWrapper}>
                        <form className={style.form}>
                            <div className={style.inputGroup}>
                                <label>Name {this.warningName()}</label>
                                <input onChange={this.onChange} name="name" value={this.state.name} className={style.input} type="text" placeholder="John Doe" />
                            </div>
                            <div className={style.inputGroup}>
                                <label>
                                    Email  {this.warningEmail()}
                                </label>
                                <input onChange={this.onChange} name="email" value={this.state.email} className={style.input} type="text" placeholder="john@email.com" />
                            </div>
                            <div className={style.inputGroup}>
                                <label>Phone {this.warningPhone()}</label>
                                <input onChange={this.onChange} name="phone" value={this.state.phone} className={style.input} type="text" placeholder="954-888-8888" />
                            </div>
                            <div className={style.inputGroup}>
                                <label>Message {this.warningMessage()}</label>
                                <textarea onChange={this.onChange} name="message" value={this.state.message} className={style.textArea} type="text" placeholder="Write a message..." />
                            </div>
                        </form>
                    </div>
                    <div className={style.buttonWrapper}>
                        <button onClick={this.onSend} className={style.submitButton} disabled={this.state.disabled ? 'disabled' : ''}>Submit</button>
                    </div>
                </div>
            </div>
        )
    }
}