import React, { Component } from 'react';
import style from './style.css';
import { Parallax } from 'react-parallax';

export default class Host extends Component {

    state = {
        strength: -200,
        url: '/images/steve_star.png',
        // paddingRight: '800px'
    }

    onResize = () => {
        if (window.innerWidth <= 500) {
            this.setState({
                strength: 0,
                paddingRight: '0px',
                url: '/images/steve_star_center.jpg'
            })
        } else if (window.innerWidth <= 700) {
            this.setState({
                strength: 0,
                paddingRight: '0px',
                url: '/images/steve_star_center.jpg'
            })
        } else if (window.innerWidth <= 1000) {
            this.setState({
                strength: 200,
                paddingRight: '0px',
                url: '/images/steve_star_center.jpg'
            })
        } else if (window.innerWidth <= 1150) {
            this.setState({
                strength: -450,
                paddingRight: '0px',
                url: '/images/steve_star_center.jpg'
            })
        } else if (window.innerWidth <= 1300) {
            this.setState({
                strength: -250,
                paddingRight: '900px',
                url: '/images/steve_star.png',
            })
        } else {
            this.setState({
                strength: -250,
                paddingRight: '800px',
                url: '/images/steve_star.png',
            })
        }
    }

    componentDidMount() {
        this.onResize();

        window.addEventListener("resize", () => {
            this.onResize();
        })
    }


    render() {
        return (
            <div id="host" className={style.mainWrapper}>
                <div className={style.parallaxContainer}>
                    <Parallax
                        // blur={1}
                        className={style.parallaxImage}
                        bgImage={this.state.url}
                        bgImageAlt="the copy"
                        strength={this.state.strength}
                        bgImageStyle={{ opacity: '0.7', paddingRight: this.state.paddingRight }}
                    >
                        <div className={style.parallaxContent}>
                        </div>
                    </Parallax>
                </div>
                <div className={style.infoSection}>
                    <div className={style.header}>
                        <h1 className={style.head}>OUR HOST</h1>
                    </div>
                    <p className={style.copy}>
                        We all know the lovable, larger-than-life, Steve Guttenberg, from many hit movies including "Police Academy, "Three Men and a Baby" and "Cocoon." His accomplishments reach much further, including being a published author, producer and director and recipient of a star on the Hollywood Walk of Fame!
                        <br />
                        <br />
                        A well-liked presence in Hollywood, Steve has appeared in Broadway productions including "The Boys Next Door" and "Prelude to a Kiss," and has produced an Emmy nominated television special, "Gangs," and "The Day After" still one of the most watched television events of this century.
                        <br />
                        <br />
                        A perfect balance of Steve Guttenburg's reliability combined with thought-provoking topics will be talked and thought about long after the program is viewed. With his beloved reputation and successful and enduring career, Steve Guttenburg is the ideal host for <span className={style.brand}>Now We Know!</span> keeping audiences interested and enlightened.
                    </p>
                    {/* <button id={style.learnButton}
                    >Learn More About WBC Productions</button> */}
                </div>
            </div>
        )
    }
}