import React from 'react';
import style from './style.css';

const phoneNumber = props => {
    return (
        <div className={style.wrapper}>
            <i className={`material-icons ${style.phoneIcon}`}>phone_forwarded</i>
            <span className={style.phone}>1-888-327-4771</span>
        </div>
    );
};

phoneNumber.propTypes = {

};

export default phoneNumber;