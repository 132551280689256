import React, { Component } from 'react';
import { TweenMax } from 'gsap';
import { Phone } from '..'
import style from './style.css';


export default class Footer extends Component {

    _handleIconGrow = (id) => {
        TweenMax.to(id, 0.2, { scale: 1.2, opacity: 0.8, transformOrigin: "50% 50%" })
    }

    _handleIconShrink = (id) => {
        TweenMax.to(id, 0.2, { scale: 1.0, opacity: 1.0, transformOrigin: "50% 50%" })
    }

    handleScroll = (location) => {
        document.getElementById(location).scrollIntoView({ behavior: "smooth", block: "start" });
    }

    render() {
        return (
            <div className={style.footerWrapper}>
                <div className={style.contentWrapper}>
                    <div className={style.contactWrapper}>
                        <h1 className={style.header}>Contact Us</h1>
                        <div id="contact" onClick={this.props.toggleContact} className={style.contactButton}>Contact</div>
                        {/* <div className={style.socialMediaIcons}>
                            <span>
                                <Icon onMouseEnter={this._handleIconGrow} onMouseLeave={this._handleIconShrink} type="facebook" color={"white"} height={30} filled={true} />
                            </span>
                            <span>
                                <Icon onMouseEnter={this._handleIconGrow} onMouseLeave={this._handleIconShrink} type="instagram" color={"white"} width={30} filled={true} />
                            </span>
                            <span>
                                <Icon onMouseEnter={this._handleIconGrow} onMouseLeave={this._handleIconShrink} type="twitter" color={"white"} width={30} filled={true} />
                            </span>
                        </div> */}
                    </div>
                    <div className={style.logoWrapper}>
                        <img alt="WBC Logo" className={style.logo} src={process.env.PUBLIC_URL + '/images/nwk_logos/nwk_bold.png'} />
                        <Phone />
                    </div>
                    <div className={style.siteMapWrapper}>

                        <ul className={style.siteMapList}>
                            <h1 className={style.header}>Site Map</h1>
                            <li onClick={() => this.handleScroll('home')} className={style.siteMapItem}>HOME</li>
                            <li onClick={() => this.handleScroll('about')} className={style.siteMapItem}>ABOUT</li>
                            <li onClick={() => this.handleScroll('portfolio')} className={style.siteMapItem}>CONTENT</li>
                            <li onClick={() => this.handleScroll('host')} className={style.siteMapItem}>OUR HOST</li>
                            <li onClick={() => this.handleScroll('values')} className={style.siteMapItem}>THE TEAM</li>
                            <li onClick={() => this.handleScroll('distribution')} className={style.siteMapItem}>DISTRIBUTION</li>
                            <li onClick={this.props.toggleContact} className={style.siteMapItem}>CONTACT</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}