import React, { Component } from 'react';
import { TweenMax } from 'gsap';
import OpenNavbar from '../../Components/openNavbar';
import { Distribution } from '../../Components';
import style from './style.css';

export default class Home extends Component {

    state = {
        contactOpen: false,
        status: false,
        open: "",
        isMounted: false,
        loading: false,
        white: true
    }

    toggle = () => {
        console.log("Changingggg")
        this.state.open === "open" ? this.setState({ open: "", status: false }) : this.setState({ open: "open", status: true });
        if (this.state.open) {
            console.log("Changing back to orgiinal")
            TweenMax.to("nav", 0.2, { backgroundColor: "rgba(255, 255, 255, 0.95)" });
        } else {
            TweenMax.to("nav", 0.2, { backgroundColor: "rgba(255, 255, 255, 0.0)" }, 0);
        }
        this.setState({ isMounted: !this.state.isMounted })
    }

    _handleHamburger = () => {
        console.log("Changingggg")
        this.state.open === "open" ? this.setState({ open: "", status: false }) : this.setState({ open: "open", status: true });
    }

    _handleOpenNavbar = () => {
        if (this.state.status) {
            return (
                <OpenNavbar history={this.props.history} toggle={this.toggle} />
            )
        }
        else {
            return null;
        }
    }

    render() {

        console.log("History2: ", this.props)
        if (this.state.loading) {
            return (
                <div className={style.loadingWrapper}>
                    <h1>Loading content...</h1>
                </div>
            )
        }

        return (
            <div className={style.homeWrapper}>
                <span id="content1">
                    <Distribution />
                </span>
            </div>
        )
    }
}