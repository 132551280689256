import React, { Component } from "react";
import { Link } from 'react-router-dom';
import OpenNavbar from '../openNavbar';
import { Phone } from '..'
import './navbar.css';
import style from './navbar.css';
import { TweenMax, TimelineMax } from 'gsap';

export default class Navbar extends Component {

  tl = new TimelineMax();
  scrollListener;

  state = {
    status: false,
    open: "",
    isMounted: false,
    logoURL: '/images/nwk_logos/nwk_bold.png',
    className: style.logo,
    isLarge: false,
  }

  toggle = () => {
    this.state.open === "open" ? this.setState({ open: "", status: false }) : this.setState({ open: "open", status: true });
    this.setState({ isMounted: !this.state.isMounted })
  }

  _handleHamburger = () => {
    this.state.open === "open" ? this.setState({ open: "", status: false }) : this.setState({ open: "open", status: true });
  }

  _handleOpenNavbar = () => {
    if (this.state.status) {
      return (
        <OpenNavbar history={this.props.history} />
      )
    } else {
      return null;
    }
  }

  toggleLogoEnter = () => {
    this.setState({
      logoURL: '/images/nwk_logos/nwk_bold.png',
    })
  }

  toggleLogoLeave = () => {
    this.setState({
      logoURL: '/images/nwk_logos/nwk_bold.png',
    })
  }

  screenSpy = (position) => {
    const elem = document.getElementById(position)
    elem.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  logoShrink = () => {
    if (this.logoEl) {
      TweenMax.to(this.logoEl, 0.2, { height: '9rem' })
    }
  }

  logoGrow = () => {
    if (this.logoEl) {
      TweenMax.to(this.logoEl, 0.2, { height: '25rem' })
    }
  }

  createEventListener = () => {
    // this.scrollListener = window.addEventListener('scroll', (e) => {

    //   //Shrink logo
    //   if (this.state.isLarge && window.scrollY >= 25) {
    //     this.setState({
    //       isLarge: false
    //     }, () => {
    //       this.logoShrink()
    //     })
    //   }
    //   //Grow logo
    //   else if (!this.state.isLarge && window.scrollY <= 25) {
    //     this.setState({
    //       isLarge: true
    //     }, () => {
    //       this.logoGrow()
    //     })
    //   }
    // });
  }

  componentWillReceiveProps() {

    //Shrink logo
    // if (window.scrollY >= 25) {
    //   this.setState({
    //     isLarge: false
    //   }, () => {
    //     this.logoShrink()
    //   })
    // }
    // //Grow logo
    // else if (window.scrollY <= 25) {
    //   this.setState({
    //     isLarge: true
    //   }, () => {
    //     this.logoGrow()
    //   })
    // }
  }

  componentDidUpdate() {
    // window.removeEventListener(this.scrollListener, (e) => e)
    // this.createEventListener();
  }

  componentDidMount() {

    // console.log("NAVBAR MOUNTED: ", window.scrollY);
    // //Shrink logo
    // if (window.scrollY >= 25) {
    //   this.setState({
    //     isLarge: false
    //   }, () => {
    //     this.logoShrink()
    //   })
    // }
    // //Grow logo
    // else if (window.scrollY <= 25) {
    //   this.setState({
    //     isLarge: true
    //   }, () => {
    //     this.logoGrow()
    //   })
    // }
    // this.createEventListener();
  }

  componentWillUnmount() {
    window.removeEventListener(this.scrollListener, (e) => e)
  }

  render() {

    return (
      <nav className="top-nav-wrapper" >
        <div className="top-nav-primary">

          <div className="logo-wrapper">
            {
              this.props.open ? null :
                <Link to='/'>
                  <img
                    alt="WBC Logo"
                    ref={r => this.logoEl = r}
                    className={style.logo}
                    src={process.env.PUBLIC_URL + this.state.logoURL} />
                </Link>
            }

          </div>
          {/* <div className={style.phoneWrapper}>
            <Phone />
          </div> */}
          <ul className="top-nav-right-list">
            <li>
              <div id="nav-icon4" className={`${this.props.open}`} onClick={this.props.toggle}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}
