import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home, Distribution } from './Pages';
import { Navbar, Footer, GlobalModal, Contact } from './Components';
import { TweenMax } from 'gsap';
import OpenNavbar from './Components/openNavbar';
import delayUnmount from './Components/delayUnmount';
import './App.css';
import style from './App.css';

const DelayedComponent = delayUnmount(OpenNavbar);

class App extends Component {

  state = {
    contactOpen: false,
    status: false,
    open: "",
    isMounted: false
  }

  toggleContact = () => {
    this.setState({
      contactOpen: !this.state.contactOpen
    })
  }

  toggle = () => {
    this.state.open === "open" ? this.setState({ open: "", status: false }) : this.setState({ open: "open", status: true });
    if (this.state.open) {

      TweenMax.to("nav", 0.2, { backgroundColor: "rgba(255, 255, 255, 0.95)" });
    } else {
      TweenMax.to("nav", 0.2, { backgroundColor: "rgba(255, 255, 255, 0.0)" }, 0);
    }
    this.setState({ isMounted: !this.state.isMounted })
  }

  _handleHamburger = () => {
    this.state.open === "open" ? this.setState({ open: "", status: false }) : this.setState({ open: "open", status: true });
  }

  _handleOpenNavbar = (history) => {
    if (this.state.status) {
      return (
        <OpenNavbar toggleContact={this.toggleContact} history={history} toggle={this.toggle} />
      )
    }
    else {
      return null;
    }
  }

  componentWillMount() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {
      console.warn("unmounting listener")
    });
  }


  render() {
    return (
      <div className="App">
        <Router>
          <DelayedComponent toggle={this.toggle} delayTime={800} isMounted={this.state.isMounted} history={this.props.history} />
          {this._handleOpenNavbar()}
          <Navbar toggle={this.toggle} _handleHamburger={this._handleHamburger} _handleOpenNavbar={this._handleOpenNavbar} status={this.state.status} open={this.state.open} isMounted={this.state.isMounted} />
          {this.state.isMounted ?
            <div onClick={this.toggle} className={style.clickable}>
            </div> : null
          }
          <Switch>
            <Route exact path="/" render={(props) => <Home {...props} />} />
            <Route exact path="/distribution" render={(props) => <Distribution {...props} />} />
          </Switch>
          <Footer toggleContact={this.toggleContact} />
        </Router>
        {
          this.state.contactOpen ?
            <GlobalModal toggle={this.toggleContact}>
              <Contact toggle={this.toggleContact} />
            </GlobalModal> : null
        }

      </div>
    );
  }
}

export default App;
