import React, { Component } from 'react';
import { SectionHeader } from '../index';
import style from './style.css';

export default class Partners extends Component {

    render() {
        return (
            <div className={style.testGlobalWrapper}>
                <SectionHeader header="BROADCAST PARTNERS" />
                <div className={style.contentWrapper}>
                    <div className={style.partners}>
                        <img alt="CNBC" className={style.partnerLogo} src={process.env.PUBLIC_URL + '/images/cnbc.svg'} />
                        <img alt="ABC" className={style.partnerLogo} src={process.env.PUBLIC_URL + '/images/abc.svg'} />
                        <img alt="FOX" id={style.foxLogo} className={style.partnerLogo} src={process.env.PUBLIC_URL + '/images/20th-century-fox-logo/fox.svg'} />
                        <img alt="CBS" className={style.partnerLogo} src={process.env.PUBLIC_URL + '/images/cbs-2.svg'} />
                        <img alt="NBC" className={style.partnerLogo} src={process.env.PUBLIC_URL + '/images/nbc.svg'} />
                    </div>
                </div>
            </div>
        )
    }
}