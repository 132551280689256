import React, { Component } from 'react';
import style from './style.css';
import { Parallax } from 'react-parallax';


export default class About extends Component {

    state = {
        strength: -100,
        url: '/images/steve.png',
        paddingLeft: null
    }



    onResize = () => {
        if (window.innerWidth <= 500) {
            this.setState({
                strength: 0,
                paddingLeft: '120px',
                url: '/images/steve-black-white-center.jpg'
            })
        } else if (window.innerWidth <= 700) {
            this.setState({
                strength: 0,
                paddingLeft: '100px',
                url: '/images/steve-black-white-center.jpg'
            })
        } else if (window.innerWidth <= 1000) {
            this.setState({
                strength: 200,
                paddingLeft: '0px',
                url: '/images/steve-black-white-center.jpg'
            })
        } else if (window.innerWidth <= 1150) {
            this.setState({
                strength: -150,
                paddingLeft: '0px',
                url: '/images/steve-black-white-center.jpg'
            })
        } else if (window.innerWidth <= 1300) {
            this.setState({
                strength: 0,
                paddingLeft: '400px',
                url: '/images/steve.png',
            })
        } else {
            this.setState({
                strength: 0,
                paddingLeft: '150px',
                url: '/images/steve.png',
            })
        }
    }

    componentDidMount() {
        this.onResize();

        window.addEventListener("resize", () => {
            this.onResize();
        })
    }


    render() {
        return (
            <div id="about" className={style.mainWrapper}>
                <div className={style.parallaxContainer}>
                    <Parallax
                        // blur={1}
                        className={style.parallaxImage}
                        bgImage={this.state.url}
                        bgImageAlt="the copy"
                        strength={this.state.strength}
                        bgImageStyle={{ opacity: '0.5', paddingLeft: this.state.paddingLeft, width: 'auto' }}
                    >
                        <div className={style.parallaxContent}>
                        </div>
                    </Parallax>
                </div>
                <div className={style.infoSection}>
                    <div className={style.header}>
                        {/* <h1 className={style.head}>NowWeKnow!</h1> */}


                        <img alt="WBC Logo" className={style.logo} src={process.env.PUBLIC_URL + '/images/nwk_logos/nwk_logo_glow.png'} />

                    </div>
                    <p className={style.copy}><span className={style.brand}>Now We Know!</span>  seeks to educate audiences with remarkable insight into industries and businesses that are impacting the way we live. Hosted by actor, author and businessman, <span className={style.hostName}>Steve Guttenberg</span>, the unique platform inspires the next generation of knowledge seekers  and viewers around the country. Providing high-level production paired with the participation of mindful businesses, <span className={style.brand}>Now We Know!</span> creates unmatched mutual benefit for the discerning modern audience.
                    <br />
                        <br />
                        <span className={style.brand}>Now We Know!</span> isn't your average TV show. Innovating in the sphere of television production for over a decade, the team at NWK! has set out to completely re-imagine what TV and television production can be. Traveling the world over, the team has contributed to countless productions in some of the most unique settings and exotic locations in addition to some of the most influential businesses in our country and around the world. We know it doesn't just take a great location to tell a great story; it's about the people, the industry and, most importantly, the collaboration between the production team and the participant.
                    <br />
                        <br />
                        <span className={style.brand}>Now We Know!</span> was created to provide knowledge to our viewers and bring to life subjects and industries that are impacting our lives. <span className={style.brand}>Now We Know!</span> brings a fresh look at interesting narratives, introduced by the well-accomplished, Steve Guttenberg, who effectively engages the audience like only he can. His affable nature and influence effectively moderates the informative themes and keeps us interested.

                    </p>

                    {/* <button id={style.learnButton}
                    >Learn More About WBC Productions</button> */}
                </div>
            </div>
        )
    }
}