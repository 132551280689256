import 'react-modal-video/scss/modal-video.scss';
import React, { Component } from 'react';
import axios from 'axios';
import { SectionHeader } from '../index';
import style from './style.css';
import ModalVideo from 'react-modal-video'
import { deepCopy } from '../../Utils/common';
import videos from './videos.json';
import { Video } from '../video';

export default class Featured extends Component {
    state = {
        isLoading: true,
        videos: videos.videos,
        height: window.innerWidth / 3 / 2,
        width: 'auto',
        white: true,
        isVideoOpen: false,
        videoObj: {},
        videoID: ''
    }

    toggleVideoModal = (videoObj) => {
        const regExp = /(\d+)/;
        const { videoSrc } = videoObj
        var match = regExp.exec(videoSrc);
        if (match) {
            this.setState((state) => ({
                videoObj: videoObj,
                videoID: match[1],
                isVideoOpen: !state.isVideoOpen
            }))
        }
    }

    loadVimeo = () => {
        let tempVideos = deepCopy(this.state.videos);
        return Promise.all(this.state.videos.map((video) => {
            const regExp = /(\d+)/;
            const { videoSrc } = video
            var match = regExp.exec(videoSrc);
            let videoURL = `https://vimeo.com/${match[0]}`
            let URL = `https://vimeo.com/api/oembed.json?url=${videoURL}&height=${this.state.height + 300}`
            if (match) {
                return axios({
                    method: 'GET',
                    url: URL,
                })
            }
        })).then(response => {
            response.forEach(({ data }, index) => {
                tempVideos[index].thumbnailURL = data.thumbnail_url
                tempVideos[index].title = data.title
                return tempVideos[index].thumbnailURL = data.thumbnail_url
            })

            return this.setState({
                videos: tempVideos
            })
        })
    }


    componentDidMount() {
        this.loadVimeo();
        if (window.innerWidth > 750) {
            this.setState({
                height: window.innerWidth / 3 / 2
            })
        } else {
            this.setState({
                height: window.innerWidth / 2
            })
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 750) {
                this.setState({
                    height: window.innerWidth / 3 / 2
                })
            } else {
                this.setState({
                    height: window.innerWidth / 2
                })
            }
        })
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => { /*Removed Listener*/ })
    }

    render() {
        return (
            <div id="portfolio" className={style.globalWrapper}>
                <SectionHeader white={true} header="An Educational Sample" />
                <div className={style.videoSegments}>
                    {
                        this.state.videos.map((videoObj, key) => <Video toggleModal={this.toggleVideoModal} height={this.state.height} videoObj={videoObj} key={key} />)
                    }
                </div>
                <ModalVideo channel='vimeo' autopause={false} autoplay={true} title={true} isOpen={this.state.isVideoOpen} videoId={`${this.state.videoID}`} onClose={() => this.setState({ isVideoOpen: false })} />
            </div>
        )
    }
}