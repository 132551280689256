import React from 'react';
import style from './style.css';

const SectionHeader = (props) => {

    return (
        <div className={style.copyWrapper}>
            {/* <img
                    alt="WBC Logo"
                    // ref={r => this.logoEl = r}
                    className={style.logo}
                    src={process.env.PUBLIC_URL + '/images/nwk_logos/nwk_bold.png'} /> */}
            <h1 className={props.white ? style.alternateHeader : null}>{props.header}</h1>
            {
                props.subHeader ?
                    <p className={props.white ? style.alternateSubHeader : null}>{props.subHeader}</p> : null
            }
            <div className={style.coloredSpacer}>
            </div>
        </div>
    )

}

export default SectionHeader;