import React, { Component } from 'react';
import { Parallax } from 'react-parallax';
import style from './style.css';


export default class Distribution extends Component {

    state = {
        strength: -300,
        paddingRight: '400px'
    }

    onResize = () => {
        if (window.innerWidth <= 700) {
            this.setState({
                strength: -300,
                paddingRight: '100px'
            })
        } else if (window.innerWidth <= 1000) {
            this.setState({
                strength: -300
            })
        } else if (window.innerWidth <= 1150) {
            this.setState({
                strength: -500
            })
        } else {
            this.setState({
                strength: -500
            })
        }
    }

    componentDidMount() {
        this.onResize();
        window.addEventListener("resize", () => this.onResize())
    }

    render() {
        return (
            <div id="distribution" className={style.globalWrapper}>
                <div style={{ background: 'rgba(0,0,0,0.3)', display: 'flex', zIndex: 2, flex: 1, position: 'absolute', height: '100%', width: '100%', top: 0, left: 0 }}>
                </div>
                <div className={style.parallaxContainer}>
                    <Parallax
                        // blur={1}
                        bgImage={'/images/steve_wide.jpg'}
                        bgImageAlt="the copy"
                        strength={this.state.strength}
                        bgImageStyle={{ paddingRight: this.state.paddingRight }}
                    >
                        <div className={style.parallaxContent}>
                        </div>
                    </Parallax>
                </div>
                <div className={style.contentWrapper}>
                    <h1 className={style.contentHeader}>Distribution</h1>
                    <p className={style.contentCopy}>With built-in relationships within educational, commercial and emerging channels,  <span className={style.brand}>Now We Know!</span> delivers diverse content to key audiences. Our experienced production team works one-on-one with participants so that every story comes across clearly and with a high level of execution usable on all leading platforms. With targeted regional and national distribution strategies, NWK is aired and viewed by the most relevant audience in each distributed market.</p>
                    <div style={{ backgroundColor: 'rgba(255,255,255,1.0)', width: '70%', height: '2px', marginTop: '1.75em' }} />
                    <h3 className={style.subHeader}><span className={style.subHeaderThin}>WHY  <span className={style.brand}>Now We Know!???</span></span></h3>
                    <hr />
                    <p className={style.contentCopy}>We are a team of caring and dedicated professionals that make the storytelling and interview process easy.
Our company works in collaboration with different industries and businesses to effectively educate the audiences with the participant's best interest in mind.
Your company and industry can be rest assured that everything from strategy through execution will be taken care in a non-invasive, timely and professional manner.</p>
                </div>
            </div>
        )
    }
}