import React, { Component } from 'react';
import { Parallax } from 'react-parallax';
import style from './style.css';


export default class Values extends Component {

    state = {
        strength: -300
    }

    componentDidMount() {
        if (window.innerWidth <= 700) {
            this.setState({
                strength: -300
            })
        } else if (window.innerWidth <= 1000) {
            this.setState({
                strength: -200
            })
        } else if (window.innerWidth <= 1150) {
            this.setState({
                strength: -500
            })
        } else {
            this.setState({
                strength: -500
            })
        }

        window.addEventListener("resize", () => {
            if (window.innerWidth <= 700) {
                this.setState({
                    strength: -300
                })
            } else if (window.innerWidth <= 1000) {
                this.setState({
                    strength: -200
                })
            }
            else if (window.innerWidth <= 1150) {
                this.setState({
                    strength: -500
                })
            } else {
                this.setState({
                    strength: -500
                })
            }
        })
    }

    render() {
        return (
            <div id="values" className={style.globalWrapper}>
                <div style={{ background: 'rgba(0,0,0,0.6)', display: 'flex', zIndex: 2, flex: 1, position: 'absolute', height: '100%', width: '100%', top: 0, left: 0 }}>
                </div>
                <div className={style.parallaxContainer}>
                    <Parallax
                        // blur={1}
                        bgImage={'/images/background_c.jpg'}
                        bgImageAlt="the copy"
                        strength={this.state.strength}
                    >
                        <div className={style.parallaxContent}>
                        </div>
                    </Parallax>
                </div>
                <div className={style.contentWrapper}>
                    <h1 className={style.contentHeader}>The Team</h1>
                    <p className={style.contentCopy}>Of course, Steve Guttenberg makes it look seamless; behind the cameras, there is a talented team of people working together to provide clear, concise and informative narratives personalized to the guests and industries, highlighting their message, ideals and ethics. THE <span className={style.brand}>Now We Know!</span> TEAM, Made up of Telly Award Winning, Cinematographers Creative Directors, Editors and Producers, <span className={style.bold}>Second-to-none</span> in the industry, use their years of expetise to provide personalized and individual attention to every segment.</p>
                    <div style={{ backgroundColor: 'rgba(255,255,255,1.0)', width: '70%', height: '2px', marginTop: '1.75em' }} />
                    <h3 className={style.subHeader}><span className={style.subHeaderThin}>Our Values</span></h3>
                    <hr />
                    <p className={style.contentCopy}>At <span className={style.brand}>Now We Know!</span>, we believe in a mutually beneficial relationship between the viewer and the business. Great industries always thrive once their true message is told. By educating the audience with insightful and interesting content, the right industries always move forward and established businesses take the next step. There is an educational message behind every business and industry and we intend to tell it!</p>
                    <h3 className={style.subHeader}><span className={style.subHeaderThin}>COMMITMENT</span></h3>
                    <hr />
                    <p className={style.contentCopy}>Due to years of experience, our team has full insight into what makes an interesting narrative; one that the audience will embrace while simultaneously ensuring that every guest's message is communicated to their satisfaction and approval. Nothing is aired without a thorough review and approval by both the <span className={style.brand}>Now We Know!</span> team AND our guests. This balancing act is accomplished by a collaboration of our video production expertise, our knowledge of what influences human behavior and how to educate the audience with relevant content that the public is interested in.</p>
                </div>
            </div>
        )
    }
}