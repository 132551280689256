import React, { Component } from 'react';
import style from './style.css';


export default class GlobalModal extends Component {

    render() {
        return (
            <div className={this.props.bottom ? style.mainWrapperBott : style.mainWrapper}>
                {/* <i onClick={this.props.toggle} className={`${style.closeButton} material-icons`}>clear</i> */}
                <div className={style.children}>
                    {
                        this.props.children !== undefined ? { ...this.props.children } :
                            null
                    }
                </div>
                <div onClick={this.props.toggle} className={style.clickable}>

                </div>
            </div>
        )
    }
}