import * as emailjs from 'emailjs-com';

export async function sendAuto(to, name) {

    var template_params = {
        "email": to,
        "from_name": process.env.REACT_APP_FROM_NAME,
        "name": name,
        "company_name": process.env.REACT_APP_COMPANY_NAME
    }

    // var service_id = "default_service";
    // var template_id = "contact_me";
    console.log("user_id ", process.env.REACT_APP_EMAIL_API_KEY)
    return emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_SEND, template_params, process.env.REACT_APP_EMAIL_API_KEY)

        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            return true
        }, (error) => {
            console.log("EMAILJS ERROR: ", error);
            return false;
        });
}

export async function sendToMe(message, email, name, phone = 'N/A') {
    var template_params = {
        "email": email,
        "name": name,
        "phone": phone,
        "message": message,
        "my_email": process.env.REACT_APP_EMAIL
    }

    // var service_id = "default_service";
    // var template_id = "contact_me";
    console.log("user_id ", process.env.REACT_APP_EMAIL_API_KEY)
    return emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_TO_ME, template_params, process.env.REACT_APP_EMAIL_API_KEY)

        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);

            alert("Sweet! I'll be contacting you shortly.");
            return true
        }, (error) => {

            alert("Something happened.. Please try again.");
            console.log("EMAILJS ERROR: ", error);
            return false;
        });
}