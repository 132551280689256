import React, { Component } from 'react';
import { SectionHeader } from '../index';
import style from './style.css';
import { TweenMax } from 'gsap';

export default class ItemScroller extends Component {

    interval = null;

    state = {
        index: 0,
        items: [
            {
                topic1: 'TECHNOLOGY',
                topic2: 'EDUCATION',
                topic3: 'HEALTHCARE'
            },
            {
                topic1: 'MANUFACTURING',
                topic2: 'AGRICULTRE',
                topic3: 'SECURITY'
            },
            {
                topic1: 'ART',
                topic2: 'SCIENCE',
                topic3: 'CULTURE'
            },
            {
                topic1: 'HOSPITALITY',
                topic2: 'TRAVEL',
                topic3: 'CHILD-CARE'
            }
        ]
    }

    setItemInterval = () => {
        this.interval = setInterval(() => {
            TweenMax.to(this.itemRef, 0.3, { opacity: 0, x: 100 })
            this.setState({
                index: this.state.index < this.state.items.length - 1 ? this.state.index + 1 : 0
            }, () => {
                TweenMax.fromTo(this.itemRef, 0.3, { opacity: 0, x: -100 }, { opacity: 1, x: 0 })
            })
        }, 6000);
    }

    onSelectOption = (id) => {
        TweenMax.to(this.itemRef, 0.3, { opacity: 0, x: 100 })
        this.setState({
            index: id
        }, () => {
            TweenMax.fromTo(this.itemRef, 0.3, { opacity: 0, x: -100 }, { opacity: 1, x: 0 })
        })
    }

    componentDidMount() {
        this.setItemInterval();
    }

    render() {

        const Separator = () => <div className={style.customDash}></div>

        return (
            <div className={style.testGlobalWrapper}>
                <div className={style.header}>
                </div>
                <div className={style.headerRight}>
                </div>
                <SectionHeader header="UPCOMING TOPICS" />
                <div className={style.contentWrapper}>
                    <div ref={e => this.itemRef = e} className={style.itemGroup}>
                        <span className={style.itemText}>{this.state.items[this.state.index].topic1}</span>
                        <Separator />
                        <span className={style.itemText}>{this.state.items[this.state.index].topic2}</span>
                        <Separator />
                        <span className={style.itemText}>{this.state.items[this.state.index].topic3}</span>
                    </div>
                    <div className={style.dots}>
                        {
                            this.state.items.map((item, key) => {
                                return (
                                    <div key={key} onClick={() => this.onSelectOption(key)} className={`${style.singleDot} ${this.state.index === key ? style.white : style.grey}`}></div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}