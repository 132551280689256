import React from 'react';
import style from './style.css';
import { TweenMax } from 'gsap';

export const Video = ({ videoObj, height, toggleModal }) => {
    let videoRef;
    let floatingTitle;
    let playButton;
    let playButtonParent;

    const giveStyle = () => {
        TweenMax.to(videoRef, 0.2, { border: '10px solid #800000' })
        TweenMax.to(playButton, 0.2, { color: 'rgb(255, 255, 255)' })
    }
    const removeStyle = () => {
        TweenMax.to(videoRef, 0.2, { border: '0px solid rgba(#000, 0)' })
        TweenMax.to(playButton, 0.2, { color: 'rgb(127, 127, 127)' })
    }

    const shrinkScale = (ref) => {
        TweenMax.to(ref, 0.2, { scale: 0.9 })
    }

    const growScale = (ref) => {
        TweenMax.to(ref, 0.2, { scale: 1.1 })
    }

    const normalizeScale = (ref) => {
        TweenMax.to(ref, 0.2, { scale: 1.0 })
    }

    return (
        <div onMouseEnter={giveStyle} onMouseLeave={removeStyle} ref={e => videoRef = e} onMouseDown={() => shrinkScale(videoRef)} onMouseUp={() => normalizeScale(videoRef)} onClick={() => toggleModal(videoObj)} className={style.video} style={{ height: height, backgroundImage: `url(${videoObj.thumbnailURL})`, backgroundSize: 'cover' }}>
            <span ref={e => floatingTitle = e} className={style.floatingTitle}>{videoObj.title}</span>
            <div ref={e => playButtonParent = e} onMouseEnter={() => growScale(playButtonParent)} onMouseLeave={() => normalizeScale(playButtonParent)} className={style.playButtonWrapper}>
                <i ref={e => playButton = e} className={`material-icons  ${style.playIcon}`}>play_circle_outline</i>
            </div>
        </div>
    )
}