import React, { Component } from 'react';
import { SectionHeader } from '../index';
import style from './style.css';

export default class Landing extends Component {

    // componentDidMount() {
    //     var promise = document.querySelector('iframe').play();

    //     if (promise !== undefined) {
    //         promise.catch(error => {
    //             // Auto-play was prevented
    //             // Show a UI element to let the user manually start playback
    //             console.warn("Autoplay has been blocked by the browser.");

    //         }).then(() => {
    //             console.log("Auto play started...")
    //             // Auto-play started
    //         });
    //     }
    // }

    render() {
        const subHeader = <span className={style.subHeader}>Marvel at the world, together.</span>;

        return (
            <div id="home" className={style.mainWrapper}>
                {/* <img
                    alt="WBC Logo"
                    // ref={r => this.logoEl = r}
                    className={style.logo}
                    src={process.env.PUBLIC_URL + '/images/nwk_logos/nwk_bold.png'} /> */}
                <SectionHeader white header="Educational media through a modern lens." subHeader={subHeader} />
                <div className={style.videoWrapper}>
                    <iframe title="landing-video" className={style.video} src="https://player.vimeo.com/video/374058672?autoplay=1&loop=0&title=0&byline=0&portrait=0&muted=1" frameBorder="0" allow="autoplay; fullscreen" webkitAllowFullscreen mozAllowFullscreen allowFullScreen></iframe>
                </div>
            </div>
        )
    }
}